import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "billing"
    }}>{`Billing`}</h1>
    <p>{`We strive to make the Dragnet pricing as transparent, simple, and fair as possible.`}</p>
    <ul>
      <li parentName="ul">{`We will automatically charge the card on file at the end of the period for services rendered.  We do NOT charge in advance of any services being provided.`}</li>
      <li parentName="ul">{`You can cancel at anytime by downgrading all client accounts to the free plan. You will not be charged for service after the client is downgraded.`}</li>
    </ul>
    <h3 {...{
      "id": "plans"
    }}>{`Plans`}</h3>
    <p>{`Plan Tiers:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`Free - 3 Free Active tokens`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`$200 / month - 50 Token Group - Available in any quantity as needed.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Free - Partner 250 - 250 tokens that can be used for demos, training, etc.  Not to be resold.`}</p>
        <h3 parentName="li" {...{
          "id": "invoices"
        }}>{`Invoices`}</h3>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Your invoice will indicate how much each client owes you, and for what period of time the service is provided. `}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`If you upgrade or downgrade a client during the period, they will appear twice on the invoice: once for each period of time at difference service levels.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`You can upgrade or downgrade as much as you want during the period.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`We prorated billing is calculated to the second.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`We will email you your invoice at the end of the period.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`You can access your historical invoices at any time by navigating to the Invoices page under your account.`}</p>
      </li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      